import React, {useEffect, useMemo, useState} from 'react';
import SeatComponent, {SeatInterface} from "./SeatComponent";
import {Hint, Neighborhood, SeatConfig} from "../../API";
import SVG from 'react-inlinesvg';
import {SeatBookings} from "../../types/SeatBookinType";
import MeetingRoomComponent, {MeetingRoomInterface} from './MeetingRoomComponent';
import {MeetingRoomBookings} from '../../types/MeetingRoomBookingType';
import OfficeHintComponent, {HintInterface} from "./OfficeHintComponent";
import {useMeetingRoomList} from "../../hooks/useMeetingRoomList";
import {MeetingRoomType} from "../../Utils/Enums";
import {t} from "i18next";
import {useSeatConfigsOfRoom} from "../../hooks/useSeatConfigsOfRoom";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import {useHasAccessByNeighborhoodAndRoomId} from "../../hooks/useHasAccessByNeighborhoodAndRoomId";
import {BookingP, MeetingRoomBookingP} from "../../types/PermissionHandling";
import {gql, useQuery} from "@apollo/client";
import {getHintsByBuilding} from "../../graphql/queries";
import {useNeighborhoodList} from "../../hooks/useNeighborhoodList";
import {reformatNeighborhoodId} from "../../Utils/Helpers";
import {useMeetingRoomInfoList} from "../../hooks/useMeetingRoomInfoList";
import {MeetingRoomInfoWithCapacity} from "../../types/MeetingRoomBookingListItemType";


interface Props {
    onSeatClick: (seatBookings: SeatBookings) => void
    onMeetingRoomClick: (meetingRoomBookings: MeetingRoomBookings) => void
    bookingList: (BookingP | MeetingRoomBookingP)[] | []
    roomId: string
    RoomPlan: string
    isTimeBookingActive: boolean
    buildingId: string
}

const TwoGetherSVGLoader: React.FC<Props> = (props) => {
    const {
        onSeatClick,
        onMeetingRoomClick,
        bookingList,
        roomId,
        RoomPlan,
        isTimeBookingActive,
        buildingId
    } = props

    const {currentUser, rooms, managedBuildings} = useMainApplicationContext();
    const [svgViewBoxWidth, setSvgViewBoxWidth] = useState("")
    const [svgViewBoxHeight, setSvgViewBoxHeight] = useState("")
    const [seatConfigs, setSeatConfigs] = useState<Map<string, SeatConfig>>();
    const {rerenderSeatConfigsTrigger} = useMainApplicationContext();

    const [hints, setHints] = useState<Hint[]>([]);
    const {refetch: refetchHints} = useQuery(gql(getHintsByBuilding), {variables: {buildingId: buildingId}});

    useEffect(() => {
        let isMounted = true;

        const fetchHints = async () => {
            try {
                const hints = await refetchHints({buildingId: buildingId});
                // @ts-ignore data surely has this property
                if (isMounted) {
                    setHints(hints?.data?.getHintsByBuilding || []);
                }
            } catch (error) {
                console.error("Error fetching hints:", error);
            }
        };

        // Execute the fetchHints function
        fetchHints().catch(console.error);

        return () => {  // cleanup function
            isMounted = false;  // set it to false on unmount
        };
    }, [buildingId]);


    const [neighborhoodList] = useNeighborhoodList(roomId ?? "");
    const [meetingRoomList] = useMeetingRoomList([roomId]);
    const [meetingRoomInfoList] = useMeetingRoomInfoList(buildingId, [roomId]);
    const {calculateIfUserHasAccessToNeighborhoods} = useHasAccessByNeighborhoodAndRoomId(roomId ?? "", currentUser);
    const [hasNeighborhoodAccess, setHasNeighborhoodAccess] = useState(new Map());
    const room = rooms.find(room => room.roomId === roomId);

    const xmlDocument: XMLDocument = useMemo(() => new DOMParser().parseFromString(RoomPlan, "image/svg+xml"), [RoomPlan, hints, meetingRoomList])
    const neighborhoodSvgLabels = getNeighborhoodSvgLabels();
    const {fetchAllSeatConfigs} = useSeatConfigsOfRoom();

    useEffect(() => {
        calculateIfUserHasAccessToNeighborhoods().then(hasNeighborhoodAccessMap => {
            setHasNeighborhoodAccess(hasNeighborhoodAccessMap);
        })
    }, [roomId, currentUser]);


    useEffect(function getSvgViewBoxDimensions() {
        let svgElements = xmlDocument.getElementsByTagName("svg")
        if (svgElements.length >= 1) {
            setSvgViewBoxWidth(svgElements[0].getAttribute("width") !== null ? svgElements[0].getAttribute("width")! : "")
            setSvgViewBoxHeight(svgElements[0].getAttribute("height") !== null ? svgElements[0].getAttribute("height")! : "")
        }
    }, [xmlDocument]);

    useEffect(() => {
        let isMounted = true;

        function fetchAllSeatConfigsByRoomId() {
            fetchAllSeatConfigs(roomId).then(seatConfigsByRoomId => {
                const seatConfigsMap: Map<string, SeatConfig> = new Map();
                seatConfigsByRoomId.forEach(seatConfig => {
                    seatConfigsMap.set(seatConfig.seatName, seatConfig);
                });

                if (isMounted) {  // check if component is still mounted
                    setSeatConfigs(seatConfigsMap);
                }
            });
        }

        fetchAllSeatConfigsByRoomId();

        return () => {  // cleanup function
            isMounted = false;  // set it to false on unmount
        };
    }, [roomId, rerenderSeatConfigsTrigger]);

    // Begin Processing Seats
    let seatArray: SeatInterface[] = useMemo(() => {
        let seats: SeatInterface[] = []
        const seatElement = xmlDocument.getElementById("seats");
        if (seatElement?.childNodes) {
            const children = seatElement.querySelectorAll("g");
            children.forEach(child => {
                let seat = child as Element;
                let table = seat.querySelector("rect");
                let bookerName = seat.querySelector(".bookerName");
                let seatName = seat.querySelector(".seatName");

                seats.push({
                    seatID: seat.getAttribute("id") ?? "0",
                    seatPosition: {
                        x: seat.getAttribute("x") ?? "0",
                        y: seat.getAttribute("y") ?? "0",
                        transform: seat?.getAttribute("transform") ?? undefined
                    },
                    tablePosition: {
                        x: table?.getAttribute("x") ?? "0",
                        y: table?.getAttribute("y") ?? "0",
                        transform: table?.getAttribute("transform") ?? undefined
                    },
                    bookerNamePosition: {
                        x: bookerName?.getAttribute("x") ?? "0",
                        y: bookerName?.getAttribute("y") ?? "0",
                        transform: bookerName?.getAttribute("transform") ?? undefined
                    },
                    tableTextPosition: {
                        x: seatName?.getAttribute("x") as string ?? "0",
                        y: seatName?.getAttribute("y") as string ?? "0",
                        transform: seatName?.getAttribute("transform") ?? undefined
                    },
                    neighborhoodId: seat.getAttribute("neighborhoodId") ?? "",
                });
                seat.querySelector(".bookerName")?.remove()
                seat.querySelector(".seatName")?.remove()
            })
        }

        return seats;
    }, [xmlDocument])


    const seatBookings: SeatBookings[] = useMemo(() => {
        return seatArray.map((seat) => {
            let bookings = bookingList
                .filter(bi => bi.__typename === "Booking")
                .map(bi => bi as BookingP)
                .filter(bi => bi.seatId === seat.seatID
                    && bi.roomId === roomId)
            return {
                seat: seat,
                bookings: bookings,
            }
        })
    }, [bookingList, roomId, seatArray]);


    const renderSeats = useMemo(() => {
        return seatBookings.map((seatBooking: SeatBookings) => {
            const hasAccess = isBuildingAdmin() || hasAccessToNeighborhoodById(seatBooking.seat.neighborhoodId);
            const seatId = seatBooking.seat.seatID;
            const isBookable = isSeatBookable(seatId)
            if (isBookable !== null) {
                return <SeatComponent
                    key={seatId}
                    seatId={seatId}
                    onClick={onSeatClick}
                    seatBookings={seatBooking}
                    isTimeBookingActive={isTimeBookingActive}
                    hasAccessToSeatByNeighborhood={hasAccess}
                    isBookable={isBookable}
                />
            }
            return <></>
        })
    }, [seatBookings, onSeatClick, isTimeBookingActive, rerenderSeatConfigsTrigger])

    // End Processing Seats

    function isSeatBookable(seatId: string | null) {
        const seatConfig = seatConfigs?.get(seatId!);
        if (!seatConfig) {
            return null;
        }
        return seatConfig?.isBookable ?? false;
    }

    function hasAccessToNeighborhoodById(neighborhoodId: string) {
        return hasNeighborhoodAccess.get(neighborhoodId) || neighborhoodId === "" || isOrgUnitAdminOfRoom() || currentUser?.isAdmin;
    }

    function isOrgUnitAdminOfRoom() {
        if (room?.orgUnitAdmin) {
            return currentUser?.adminOrgUnits.map(orgUnit => orgUnit.orgId).includes(room.orgUnitAdmin);
        }

        return false;
    }

    function isBuildingAdmin() {
        if (!managedBuildings || !buildingId) {
            return false;
        }
        return managedBuildings.some(b => b.buildingId === buildingId);
    }

    // Begin Processing Meeting Rooms

    function getMeetingRoomCapacity(roomCap: Element | null, dbMeetingRoom: MeetingRoomInfoWithCapacity | undefined) {
        if (typeof dbMeetingRoom?.capacity === 'number' && dbMeetingRoom.capacity >= 0) {
            return dbMeetingRoom.capacity;
        } else if (roomCap?.getAttribute("roomCap")) {
            return Number(roomCap.getAttribute("roomCap")) || 0;
        }
        return 0;
    }

    function getMeetingRoomName(roomGroup: Element, dbMeetingRoom: MeetingRoomInfoWithCapacity | undefined): string {
        let name = dbMeetingRoom?.name ?? roomGroup.getAttribute("id") ?? "";

        if (name.length > 60) {
            name = name.substring(0, 60);
        }

        return name;
    }

    function getNeighborhoodSvgLabels() {
        return Array.from(xmlDocument
            ?.getElementById("_neighboorhoodLabels")
            ?.querySelectorAll("text") ?? []);
    }

    function getSvgLabelsByNeighborhoodId(neighborhoodId: string) {
        return neighborhoodSvgLabels.filter(l => neighborhoodId === l.getAttribute("neighborhoodId"));
    }

    function getNeighborhoodNameLabelAttributes(neighborhoodSvgLabel: SVGTextElement) {
        return {
            labelPos: neighborhoodSvgLabel.getAttribute("pos"),
            maxLen: neighborhoodSvgLabel.getAttribute("maxLength"),
            initX: neighborhoodSvgLabel.getAttribute("initX"),
            y: neighborhoodSvgLabel.getAttribute("y")
        };
    }

    function setNeighborhoodNameToSvgLabel(neighborhoodName: string, neighborhoodSvgLabel: SVGTextElement) {
        const {labelPos, maxLen, initX, y} = getNeighborhoodNameLabelAttributes(neighborhoodSvgLabel);
        const offsetX = 6;

        if (!labelPos || !maxLen || !Number.isInteger(+maxLen) || !initX || !y) {
            return;
        }

        removeChildrenOfSvgElementBySelector(neighborhoodSvgLabel, "tspan");
        // split neighborhood name into parts, so each part fit the line length
        const neighNameParts = splitNeighborhoodName(neighborhoodName, +maxLen);

        // if multiple lines, shift the label upwards
        if (labelPos === "top") {
            const labelX = `${+initX + offsetX * (neighNameParts.length - 1)}`;
            neighborhoodSvgLabel.setAttribute("x", labelX);
        }

        // each line of text (name part) is a new tspan, which has to be shifted along X axis
        for (let i = 0; i < neighNameParts.length; i++) {
            let tspanX;
            if (labelPos === "top") {
                tspanX = +initX + offsetX * (neighNameParts.length - 1 - i);
            } else {
                tspanX = +initX - offsetX * i;
            }

            const newTspan = createSvgTspan(neighNameParts[i], tspanX.toString(), y);
            neighborhoodSvgLabel.appendChild(newTspan);
        }
    }

    function splitNeighborhoodName(neighborhoodName: string, maxLen: number): string[] {
        const nameParts: string[] = [];
        const namesSplitBySpace = neighborhoodName.split(" ");
        for (const nameBySpace of namesSplitBySpace) {
            if (nameBySpace.length > maxLen) {
                // if the name part is too long, then we hard break it
                getChunksFromString(nameBySpace, maxLen)?.forEach(chunkStr => nameParts.push(chunkStr.trim()));
            } else {
                if (nameParts.length === 0) {
                    nameParts.push("");
                }
                let nameAppendCandidate = `${nameParts[nameParts.length - 1]} ${nameBySpace}`.trim();
                if (nameAppendCandidate.length <= maxLen) {
                    // if it fits the length, append this part to the previous part
                    nameParts[nameParts.length - 1] = nameAppendCandidate;
                } else {
                    // add it as new part (line) otherwise
                    nameParts.push(nameBySpace);
                }
            }
        }

        return nameParts;
    }

    const getChunksFromString = (str: string, chunkSize: number) => {
        return str.match(new RegExp(`.{1,${chunkSize}}`, 'g'));
    }

    function createSvgTspan(text: string, x: string, y: string) {
        const tspan = document.createElement("tspan");
        tspan.id = `tspan-${text}`;
        tspan.textContent = text;
        tspan.setAttribute("x", x);
        tspan.setAttribute("y", y);
        return tspan;
    }

    function removeChildrenOfSvgElementBySelector(svgElement: SVGElement, selector: string) {
        svgElement.querySelectorAll(selector).forEach(childElem => svgElement.removeChild(childElem));
    }

    function setNeighborhoodNamesToSvgLabels(neighborhood: Neighborhood) {
        const neighborhoodName = neighborhood.name ?? reformatNeighborhoodId(neighborhood.neighborhoodId);
        const svgLabelsByNeighId = getSvgLabelsByNeighborhoodId(neighborhood.neighborhoodId);
        svgLabelsByNeighId.forEach(neighSvgLabel => {
            setNeighborhoodNameToSvgLabel(neighborhoodName.trim(), neighSvgLabel);
        });
    }

    useEffect(() => {
        neighborhoodList.forEach(neighborhood => setNeighborhoodNamesToSvgLabels(neighborhood));
    }, [neighborhoodList, xmlDocument]);

    let meetingRoomArray: MeetingRoomInterface[] = useMemo(() => {
        let meetingRooms: MeetingRoomInterface[] = []
        const meetingRoomElement = xmlDocument.getElementById("meetingRooms")

        if (!meetingRoomElement?.childNodes) return meetingRooms;

        const children = meetingRoomElement.querySelectorAll("g");
        children.forEach(child => {
            let roomGroup = child as Element;
            let room = roomGroup.querySelector("path");
            let roomCap = roomGroup.querySelector(".roomCapacity");
            let roomIcon = roomGroup.querySelector(".icon");
            let meetingRoomCapacity: number;
            let meetingRoomName: string;

            const meetingRoomId = roomGroup.getAttribute("id") ?? "0";
            const neighborhoodId = roomGroup.getAttribute("neighborhoodId") ?? "";
            const dbMeetingRoom = meetingRoomList.find(meetingRoom => meetingRoom.meetingRoomId === meetingRoomId);
            const dbMeetingRoomInfoWithCapacity = meetingRoomInfoList.find(meetingRoom => meetingRoom.meetingRoomId === meetingRoomId);

            meetingRoomCapacity = getMeetingRoomCapacity(roomCap, dbMeetingRoomInfoWithCapacity);
            meetingRoomName = getMeetingRoomName(roomGroup, dbMeetingRoomInfoWithCapacity);

            meetingRooms.push({
                roomId: roomId,
                neighborhoodId: neighborhoodId,
                meetingRoomID: roomGroup.getAttribute("id") ?? "0",
                meetingRoomName: meetingRoomName,
                roomCap: meetingRoomCapacity,
                roleIds: dbMeetingRoom?.roleIds ?? [],
                isBookable: dbMeetingRoom?.isBookable ?? false,
                type: dbMeetingRoom?.type ?? MeetingRoomType.INTERNAL,
                equipmentIds: dbMeetingRoom?.equipmentIds ?? [],
                meetingRoomSeatingConfNames: dbMeetingRoom?.seatingConfNames ?? [],
                meetingRoomSeatingConfDays: dbMeetingRoom?.seatingConfDays ?? 0,
                meetingRoomSeatingConfImageId: dbMeetingRoom?.seatingConfImageId ?? '',
                meetingRoomGroupPosition: {
                    x: parseInt(roomGroup?.getAttribute("x") ?? "0"),
                    y: parseInt(roomGroup?.getAttribute("y") ?? "0"),
                    transform: roomGroup?.getAttribute("transform") ?? undefined
                },
                meetingRoomPosition: {
                    x: parseInt(room?.getAttribute("x") ?? "0"),
                    y: parseInt(room?.getAttribute("y") ?? "0"),
                    transform: room?.getAttribute("transform") ?? undefined
                },
                meetingRoomDimension: {
                    width: parseInt(room?.getAttribute("width") ?? "0"),
                    height: parseInt(room?.getAttribute("height") ?? "0"),
                },
                meetingRoomShape: room?.getAttribute("d") ?? "0",
                meetingRoomIconPosition: {
                    x: parseInt(roomIcon?.getAttribute("x") ?? "0"),
                    y: parseInt(roomIcon?.getAttribute("y") ?? "0"),
                    transform: roomIcon?.getAttribute("transform") ?? undefined
                },
                meetingRoomIconDimension: {
                    width: parseInt(roomIcon?.getAttribute("width") ?? "0"),
                    height: parseInt(roomIcon?.getAttribute("height") ?? "0"),
                }
            });

            const updateTspanContent = (selector: string, value: string, label?: string) => {
                const element = roomGroup.querySelector(selector);
                const tspan = element?.querySelector("tspan");

                if (tspan) {
                    if (label) {
                        tspan.textContent = `${label}: ${value}`;
                    } else {
                        tspan.textContent = value;
                    }
                }
            };

            roomGroup.querySelector(".bookerName")?.remove()
            roomGroup.querySelector(".icon")?.remove()

            updateTspanContent(".roomCapacity", meetingRoomCapacity.toString(), t("meeting_room_capacity"));
            updateTspanContent(".roomName", meetingRoomName);
        })
        return meetingRooms;
    }, [xmlDocument]);

    const meetingRoomBookings: MeetingRoomBookings[] = meetingRoomArray
        .map((meetingRoom: MeetingRoomInterface): MeetingRoomBookings => {
            let bookings = bookingList
                .filter(b => b.__typename === "MeetingRoomBooking")
                .map(bi => bi as MeetingRoomBookingP)
                .filter(bi => bi.meetingRoomId === meetingRoom.meetingRoomID && bi.roomId === roomId)
            //TODO verify that these bookings are not used for editing/deleting
            return {
                meetingRoom: meetingRoom,
                bookings: bookings,
            }
        })

    const renderMeetingRooms = useMemo(() => {
        return meetingRoomBookings.map((meetingRoomBooking: MeetingRoomBookings) => {
            const hasAccess = isBuildingAdmin() || hasAccessToNeighborhoodById(meetingRoomBooking.meetingRoom.neighborhoodId);

            return <MeetingRoomComponent
                key={meetingRoomBooking.meetingRoom.meetingRoomID}
                onClick={onMeetingRoomClick}
                meetingRoomBookings={meetingRoomBooking}
                isTimeBookingActive={isTimeBookingActive}
                meetingRoomsCurrentRoom={meetingRoomList}
                hasAccess={hasAccess}
            />
        })

    }, [meetingRoomBookings, onMeetingRoomClick, isTimeBookingActive])
    // End Processing Meeting Rooms

    // Begin Processing Hints for offices
    let hintArray: HintInterface[] = useMemo(() => {
        let roomHints: HintInterface[] = []
        const officeElement = xmlDocument.getElementById("offices");
        if (!officeElement?.childNodes) return roomHints;
        const hintRects = officeElement.querySelectorAll("rect");
        hintRects.forEach(hint => {
            const hintDb = hints.find(h => h.hintId === hint.getAttribute("id") ?? "0");
            if (hintDb) {
                const meetingRoom = meetingRoomArray.find(meetRoom => meetRoom.meetingRoomID === hintDb.hintId.slice(5))
                roomHints.push({
                    hintId: hint.getAttribute("id") ?? "0",
                    text: hintDb.text,
                    isVisible: hintDb.text.length > 0 || meetingRoom?.equipmentIds?.length! > 0 ||  meetingRoom?.meetingRoomSeatingConfNames?.length! > 0,
                    hintIconPosition: {
                        x: parseInt(hint?.getAttribute("x") ?? "0"),
                        y: parseInt(hint?.getAttribute("y") ?? "0"),
                        transform: hint?.getAttribute("transform") ?? undefined
                    },
                    hintIconDimension: {
                        width: parseInt(hint?.getAttribute("width") ?? "0"),
                        height: parseInt(hint?.getAttribute("height") ?? "0"),
                    }
                });
            }
            hint.remove();
        })
        return roomHints;
    }, [xmlDocument])

    const renderHints = useMemo(() => {
        return hintArray.map((hint: HintInterface) => {
            return <OfficeHintComponent hint={hint} key={hint.hintId}/>
        })

    }, [hintArray])
    // End Processing Hints for offices


    const style = {width: "100vw", height: "100vh"}
    const viewBox = `0 0 ${svgViewBoxWidth} ${svgViewBoxHeight}`;

    return (
        <div>
            {svgViewBoxWidth !== "" && svgViewBoxHeight !== "" &&
                <svg style={style} viewBox={viewBox}>
                    <SVG src={new XMLSerializer().serializeToString(xmlDocument)}/>
                    {renderSeats}
                    {renderMeetingRooms}
                    {renderHints}
                </svg>}
        </div>
    )
}

export default TwoGetherSVGLoader;
