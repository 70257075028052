import {Booking, Building, MeetingRoomBooking, Neighborhood, Room} from "../../API";
import React, {CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import "../../styles/styleRoomPlan.css"
import {useBookingList} from "../../hooks/useBookingList";
import Dialog from "@mui/material/Dialog";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import ZoomControlComponent from "./ZoomControlComponent";
import {User} from "../../services/UserClient";
import RoomPlanPanningComponent from "./RoomPlanPanningComponent";
import TwoGetherSVGLoader from "../svgComponents/TwoGetherSVGLoader";
import {useRoomPlanFromS3} from "../../hooks/useRoomPlanFromS3";
import {useOutsideDivClick} from "../../hooks/useOutsideDivClick";
import RoomPlanContext from "../../context/RoomPlanContext";
import {itemToDisplay} from "../../types/RoomPlanContextTypes";
import RoomPlanHoverDialog from "./RoomPlanHoverDialog";
import {SeatBookings} from "../../types/SeatBookinType";
import {useFilterContext} from "../../hooks/useFilterContext";
import ItemClickedDialog from "./ItemClickedDialogComponents/ItemClickedDialog";
import {MeetingRoomBookings} from "../../types/MeetingRoomBookingType";
import BookingMeetingRoomList from "../svgComponents/meetingRoomRenderComponents/BookingRoomMeetingList";
import {BookingType} from "../../types/BookingType";
import {BookingDialog, BookingDialogProps} from "../BookingDialog/BookingDialog";
import {getMeetingRoomType, MeetingRoomType} from "../../Utils/Enums";
import {BookingP, MeetingRoomBookingP} from "../../types/PermissionHandling";
import {adjustManagableStateByMeetingRoom, adjustManagableStateByNeighborhood} from "../../Utils/Roles";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";
import {Button, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {useMainApplicationContext} from "../../hooks/useMainApplicationContext";
import ErrorBoundary from "../ErrorBoundary";
import {
    EditSeatBookingContextType,
    EditSeatBookingFromPlanContext
} from "../EditSeatBookingDialog/EditSeatBookingFromPlanContext";
import {
    ConfirmDeleteSeatBookingContextType,
    ConfirmSeatBookingDeletionFromPlanContext
} from "./Contexts/ConfirmSeatBookingDeletionFromPlanContext";
import {MeetingRoomInterface} from "../svgComponents/MeetingRoomComponent";


interface Props {
    neighborhoodsOfSelectedRoom: Neighborhood[],
    selectedNeighborhood: Neighborhood | undefined
    buildingRoleIds: string[],
    room: Room
    date: Date
    building: Building | undefined
    currentUser: User
    triggerNeighborhoodUpdate: boolean
    roomFinderSelectedRoom: string | undefined
    triggerRoomFinderSelectedRoomUpdate: boolean
}

type mobileBookingButton = {
    isVisible: boolean;
    position: { x: number, y: number };
    bookings: BookingP[] | MeetingRoomBookingP[];
    bookingType: BookingType;
}

export function highlightSeats(element: Element, predicate: boolean) {
    predicate ?
        element.setAttribute("class", "highlighted") :
        element.setAttribute("class", "")
}

const emptyStringArray: string[] = [];
const emptyString = "";

const RoomPlanComponent = (props: Props) => {
    const {
        buildingRoleIds,
        neighborhoodsOfSelectedRoom,
        selectedNeighborhood,
        room,
        building,
        date,
        currentUser,
        triggerNeighborhoodUpdate,
        roomFinderSelectedRoom,
        triggerRoomFinderSelectedRoomUpdate
    } = props;

    const {isNoFullscreen, isMobileDevice} = useDeviceMediaType();
    const {t} = useTranslation();

    const {userSettingsObject} = useMainApplicationContext();

    const zoomContext = useRef<any>(null);
    const dateISO = date.toLocaleDateString('sv', {timeZone: 'Europe/Berlin'}).substring(0, 10)

    const [selectedSeat, setSelectedSeat] = useState<string>("");
    const [currentRoomCap, setCurrentRoomCap] = useState<number>(0);
    const [currentMeetRoomType, setCurrentMeetRoomType] = useState<MeetingRoomType>(MeetingRoomType.INTERNAL);
    // const [currentMeetRoom, setCurrentMeetRoom] = useState<MeetingRoomInterface | undefined>(undefined);

    // TODO: Maybe possible to get rid of these states
    const [clickedOnBookedSeat, setClickedOnBookedSeat] = useState<boolean>(false);
    const [selectedSeatIsBookedByMe, setSelectedSeatIsBookedByMe] = useState<boolean>(false);
    const [showMultiBookingDialog, setShowMultiBookingDialog] = useState(false);
    const [showMultiBookingMeetingRoomDialog, setShowMultiBookingMeetingRoomDialog] = useState(false);
    const [showItemClickedDialog, setShowItemClickedDialog] = useState(false);
    const [deletableSeatBookings, setDeletableSeatBookings] = useState<(BookingP)[]>([]);
    const [deletableMRBookings, setDeletableMRBookings] = useState<(MeetingRoomBookingP)[]>([]);

    const [itemsToDisplay, setItemsToDisplay] = useState<itemToDisplay[]>([])
    const [bookingsToDisplay, setBookingsToDisplay] = useState<(Booking | MeetingRoomBooking)[]>([])
    const [currentMeetingRoomBookings, setCurrentMeetingRoomBookings] = useState<MeetingRoomBookings | null>(null);
    const [isDeletingMeetingRoomBookings, setIsDeletingMeetingRoomBookings] = useState<boolean>(false);
    const [zoomingState, setZoomingState] = useState<boolean>(false);
    const [mobileBookingButton, setMobileBookingButton] = useState<mobileBookingButton>();

    // For updating booking list in seat popup while it is open
    // previously it was only updated on open
    // this state is set when handleclickonseat is called by child component, there we can extract the seatid
    const [lastClickedSeatId, setLastClickedSeatId] = useState<string | null>(null);

    const roomPlanContainerRef = useRef<any>(null);
    const mouse = useRef({x: 0, y: 0});
    const {isMobile} = useDeviceMediaType();

    const roomPlan = useRoomPlanFromS3(room)
    const bookingList = useBookingList(
        room?.roomId ?? emptyString,
        room?.orgUnitId ?? emptyString,
        buildingRoleIds,
        emptyStringArray /*TODO pass room.manageRoleIds*/,
        dateISO);

    const initialScale = (room?.roomPlanScaleFactor) ? room.roomPlanScaleFactor / 100 : 1;
    let minScale = Math.min(initialScale, 0.5);
    const maxScale = isMobile ? 40 : 10;
    const {isRoomDropdownFocussed, isBuildingDropdownFocused} = useFilterContext();

    const offsetPopUpDialog: number = 10;

    const handleMouseMove = (e: React.MouseEvent) => {
        mouse.current = {
            ...mouse.current,
            x: e.pageX,
            y: e.pageY + 12
        }
    }

    const handleClickOnSeat = useCallback((selectedSeatBookings: BookingP[]) => {
        if (selectedSeatBookings.length > 0) {
            setClickedOnBookedSeat(true) //TODO Is it possible to get rid of these states? Look into Multibooking Component

            // Store seatId so that we know in this component which seat was clicked/which bookings should be in the popup for editing/deleting
            // these bookings all have the same seat id
            setLastClickedSeatId(selectedSeatBookings[0].seatId);
        } else {
            setLastClickedSeatId(null);
        }

        const _deletableBookings = selectedSeatBookings.filter(b => b.managingAllowed)
        if (selectedSeatBookings.some(b => b.bookerId === currentUser.ID)) {
            setSelectedSeatIsBookedByMe(true); //TODO Is it possible to get rid of these states? Look into Multibooking Component
        }
        if (_deletableBookings.length > 0) {
            setDeletableSeatBookings(_deletableBookings);
            setShowItemClickedDialog(true);
            return;
        }
        setShowMultiBookingDialog(true);

    }, [currentUser.ID, currentUser.isAdmin])

    const handleClickOnMeetingRoom = useCallback((selectedMeetingRoomBookings: MeetingRoomBookingP[]) => {
        if (selectedMeetingRoomBookings.length > 0) {
            setClickedOnBookedSeat(true) //TODO Is it possible to get rid of these states? Look into Multibooking Component
        }
        const _deletableBookings = selectedMeetingRoomBookings.filter(b => b.managingAllowed)
        if (selectedMeetingRoomBookings.some(b => b.bookerId === currentUser.ID)) {
            setSelectedSeatIsBookedByMe(true); //TODO Is it possible to get rid of these states? Look into Multibooking Component
        }
        if (_deletableBookings.length > 0) {
            setDeletableMRBookings(_deletableBookings);
            setIsDeletingMeetingRoomBookings(true);
            setShowItemClickedDialog(true);
            return;
        }
        setShowMultiBookingMeetingRoomDialog(true);

    }, [currentUser.ID, currentUser.isAdmin, currentUser.isOrgUnitAdmin])

    // For updating booking list in seat popup while it is open
    // previously it was only updated on open
    const latestEditableDeletableSeatBookingsOfLastClickedSeat = useMemo(() => {
        if (lastClickedSeatId === null){
            return [];
        }
        // TODO check if neighborhood roles/permissions have to be handled here
        return bookingList
            .filter(b => b.__typename === "Booking")
            .map(b => b as BookingP)
            .filter(b => b.seatId === lastClickedSeatId)
            .filter(b => b.managingAllowed)
    }, [bookingList, lastClickedSeatId]);

    // For updating booking list in seat popup while it is open
    // previously it was only updated on open
    useEffect(() => {
        // update lists with deleteable/editable bookings when popup is open
        if (!showItemClickedDialog){
            return;
        }
        if (lastClickedSeatId === null){
            return;
        }
        setDeletableSeatBookings(latestEditableDeletableSeatBookingsOfLastClickedSeat);

    }, [showItemClickedDialog, lastClickedSeatId, latestEditableDeletableSeatBookingsOfLastClickedSeat]);

    const renderMobileBookingButton = useMemo(() => {
        return (
            mobileBookingButton?.isVisible &&
            <ClickAwayListener onClickAway={() => setMobileBookingButton(undefined)}>
                <div style={{
                    position: 'absolute',
                    left: mobileBookingButton.position.x,
                    top: mobileBookingButton.position.y
                }}>
                    <Button variant="contained"
                            onClick={() => {
                                if (mobileBookingButton?.bookingType === 'seat') handleClickOnSeat(mobileBookingButton.bookings as BookingP[]);
                                else if (mobileBookingButton?.bookingType === 'meetingRoom') handleClickOnMeetingRoom(mobileBookingButton.bookings as MeetingRoomBookingP[]);
                                setMobileBookingButton(undefined);
                            }}
                    >{t('book')}</Button>
                </div>
            </ClickAwayListener>
        )
    }, [mobileBookingButton, t, handleClickOnSeat, handleClickOnMeetingRoom])

    const onSeatClick = useCallback((selectedSeatBookings: SeatBookings) => {
        const neighborhoodId = selectedSeatBookings.seat.neighborhoodId;
        const seatBookings = selectedSeatBookings.bookings
            .map((b) => adjustManagableStateByNeighborhood(b, neighborhoodId, neighborhoodsOfSelectedRoom, currentUser.admin2GetherRoles));
        setSelectedSeat(selectedSeatBookings.seat.seatID)

        if (isMobileDevice) {
            setMobileBookingButton({
                isVisible: true,
                position: {x: mouse.current.x, y: mouse.current.y - 40},
                bookings: seatBookings,
                bookingType: "seat"
            })
            return;
        }

        handleClickOnSeat(seatBookings)
    }, [handleClickOnSeat, neighborhoodsOfSelectedRoom, isMobileDevice])

    //TODO ask why roomCap was nullable
    const onMeetingRoomClick = useCallback((selectedMeetingRoomBookings: MeetingRoomBookings) => {
        const neighborhoodId = selectedMeetingRoomBookings.meetingRoom.neighborhoodId;
        const meetingRoomRoleIds = selectedMeetingRoomBookings.meetingRoom.roleIds;
        const meetRoomBookings = selectedMeetingRoomBookings.bookings
            .map(b => adjustManagableStateByNeighborhood(b, neighborhoodId, neighborhoodsOfSelectedRoom, currentUser.admin2GetherRoles))
            .map(b => adjustManagableStateByMeetingRoom(b, meetingRoomRoleIds, currentUser.admin2GetherRoles));
        setSelectedSeat(selectedMeetingRoomBookings.meetingRoom.meetingRoomID)
        setCurrentRoomCap(selectedMeetingRoomBookings.meetingRoom.roomCap)
        setCurrentMeetRoomType(getMeetingRoomType(selectedMeetingRoomBookings.meetingRoom.type));
        setCurrentMeetingRoomBookings(selectedMeetingRoomBookings) //do they need to be filtered based on roles?

        if (isMobileDevice) {
            setMobileBookingButton({
                isVisible: true,
                position: {x: mouse.current.x, y: mouse.current.y - 40},
                bookings: meetRoomBookings,
                bookingType: "meetingRoom"
            })
            return;
        }

        handleClickOnMeetingRoom(meetRoomBookings)//for filtering deletable bookings
    }, [handleClickOnMeetingRoom, neighborhoodsOfSelectedRoom, isMobileDevice])

    const closeDeleteBookingDialog = () => {
        setShowItemClickedDialog(false)
        setSelectedSeat("")
        setSelectedSeatIsBookedByMe(false)
        setClickedOnBookedSeat(false)
    }

    const {isBookingEditDialogOpen} = useContext(EditSeatBookingFromPlanContext) as EditSeatBookingContextType;
    const {isSeatBookingDeletionConfirmationDialogOpen} = useContext(ConfirmSeatBookingDeletionFromPlanContext) as ConfirmDeleteSeatBookingContextType;

    const outsideClickRef = useOutsideDivClick(function handleOutsideClick() {
        if (isBookingEditDialogOpen){
            // don't close seat paper popup, when user clicks inside open seat booking edit dialog
            return;
        }
        if (isSeatBookingDeletionConfirmationDialogOpen){
            // don't close seat paper popup, when user clicks inside open seat booking deletion confirmation dialog
            return;
        }
        setShowItemClickedDialog(false)
        setIsDeletingMeetingRoomBookings(false)
        setCurrentMeetingRoomBookings(null)
        setClickedOnBookedSeat(false);
        setCurrentRoomCap(0)
        setDeletableSeatBookings([]);
        setDeletableMRBookings([]);
        setSelectedSeat("")
    })

    const styleHoverTableCell: CSSProperties = {
        textAlign: 'left',
        padding: "4px",
        borderBottom: "none",
        backgroundColor: "transparent"
    };

    const renderRoomPlanHoverDialog = useMemo(() => {
        if (itemsToDisplay.length > 0) {
            return (
                <ErrorBoundary>
                    <RoomPlanHoverDialog mousePosX={mouse.current.x} mousePosY={mouse.current.y}
                                         offset={offsetPopUpDialog} styleCell={styleHoverTableCell}/>
                </ErrorBoundary>

            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsToDisplay])

    const renderMeetingRoomBookingListHoverDialog = useMemo(() => {
        if (bookingsToDisplay.length > 0) {
            return (
                <BookingMeetingRoomList mousePosX={mouse.current.x} mousePosY={mouse.current.y}
                                        offset={offsetPopUpDialog} currentUserID={currentUser.ID}
                                        styleCell={styleHoverTableCell}/>
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingsToDisplay])

    const renderSVG = useMemo(() => {
        return (
            <TwoGetherSVGLoader onSeatClick={onSeatClick}
                                onMeetingRoomClick={onMeetingRoomClick}
                                bookingList={bookingList}
                                roomId={room.roomId}
                                RoomPlan={roomPlan}
                                isTimeBookingActive={!!room.isTimeActive}
                                buildingId={room.buildingId ?? ""}
            />
        )
    }, [onSeatClick, onMeetingRoomClick, bookingList, room.roomId, room.isTimeActive, roomPlan])

    const renderRoomPlan = useMemo(() => {
        if (zoomingState) {
            setZoomingState(false);
        }
        return (
            <div>
                {room &&
                    <div style={{
                        overflowY: "hidden",
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <TransformWrapper
                            key={room.roomId}
                            ref={zoomContext}
                            initialScale={initialScale}
                            minScale={minScale}
                            maxScale={maxScale}
                            doubleClick={{disabled: true}}
                            panning={{disabled: false, velocityDisabled: true}}
                            centerOnInit={true}
                            limitToBounds={false}
                            centerZoomedOut={false}
                            onTransformed={() => {
                                setZoomingState(true)
                            }}
                        >
                            {({zoomIn, zoomOut, centerView}: any) => (
                                <React.Fragment>
                                    <ZoomControlComponent
                                        zoomIn={zoomIn}
                                        zoomOut={zoomOut}
                                        reset={() => {
                                            centerView(initialScale);
                                        }}
                                        zoomInDisabled={zoomContext?.current?.instance.transformState.scale >= maxScale}
                                        zoomOutDisabled={zoomContext.current === null ||
                                            (zoomContext.current.instance.transformState &&
                                                zoomContext.current.instance.transformState.scale <= minScale)}

                                    />
                                    <div className={"divTransform"} onMouseMove={handleMouseMove}>
                                        <TransformComponent>
                                            <div className={"divSVG"} ref={roomPlanContainerRef}>
                                                {renderSVG}
                                            </div>
                                        </TransformComponent>
                                    </div>
                                </React.Fragment>
                            )}
                        </TransformWrapper>
                    </div>
                }
            </div>
        )
    }, [initialScale, minScale, renderSVG, room, zoomingState])

    const paperProps = useMemo(() => {
        return {style: {display: "inline-table"}}
    }, [])


    const bookingDialogSeat = useMemo(() => {
        function handleOnClose() {
            setShowMultiBookingDialog(false);
            setSelectedSeat("");
            setClickedOnBookedSeat(false);
        }

        function getNoConsentDialog() {
            return (<>
                <DialogTitle>
                    {t("my_settings-consent_title")}
                </DialogTitle>
                <DialogContent>
                    {t("seat_booking-no_consent_text")}
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} color={"primary"} onClick={handleOnClose}>OK</Button>
                </DialogActions>
            </>)
        }

        const bookingDialogProps: BookingDialogProps = {
            bookingType: "seat",
            handleClose: () => handleOnClose(),
            dateToSelectInitially: date,
            bookerName: currentUser.name,
            bookerGivenName: currentUser.givenName,
            bookerFamilyName: currentUser.familyName,
            room: room,
            building: building,
            seatId: selectedSeat,
            selectedSeatIsBookedOnSelectedDay: clickedOnBookedSeat,
            selectedSeatIsBookedByMeOnSelectedDay: selectedSeatIsBookedByMe,
            bookingList: bookingList,
            roomCap: 0,
            meetRoomType: currentMeetRoomType,
        }

        return (
            <Dialog PaperProps={isNoFullscreen ? undefined : paperProps} open={showMultiBookingDialog}
                    onClose={handleOnClose}
                    scroll={"paper"}
                    style={isNoFullscreen ? {maxHeight: "650px"} : {}}>
                    <BookingDialog {...bookingDialogProps}/>
            </Dialog>
        )
    }, [userSettingsObject, date, currentUser.name, currentUser.givenName, currentUser.familyName, room, selectedSeat, clickedOnBookedSeat, selectedSeatIsBookedByMe, bookingList, isNoFullscreen, paperProps, showMultiBookingDialog])

    const renderItemClickedDialog = useMemo(() => {
        const bookingType: BookingType = isDeletingMeetingRoomBookings ? "meetingRoom" : "seat";
        const bookOtherDateTime = () => {
            setShowItemClickedDialog(false);
            if (!isDeletingMeetingRoomBookings) {
                setShowMultiBookingDialog(true);
                return;
            }
            setShowMultiBookingMeetingRoomDialog(true);
            setIsDeletingMeetingRoomBookings(false);
        }
        return <>
            {showItemClickedDialog &&
                <div ref={outsideClickRef}>
                    <ItemClickedDialog
                        bookingType={bookingType}
                        position={{left: mouse.current.x, top: mouse.current.y}}
                        offset={offsetPopUpDialog}
                        isTimeActive={room.isTimeActive!}
                        deletableSeatBookings={deletableSeatBookings}
                        deletableMRBookings={deletableMRBookings}
                        handleBookOtherDateTime={bookOtherDateTime}
                        closeDeleteBookingDialog={closeDeleteBookingDialog}
                        meetingRoomBookingProps={currentMeetingRoomBookings}
                    />
                </div>
            }
        </>

    }, [isDeletingMeetingRoomBookings, showItemClickedDialog, outsideClickRef, room.isTimeActive, deletableSeatBookings, deletableMRBookings, currentMeetingRoomBookings])

    const providerValues = useMemo(() => ({
        room,
        currentUserID: props.currentUser.ID,
        itemsToDisplay,
        setItemsToDisplay,
        bookingsToDisplay,
        setBookingsToDisplay
    }), [bookingsToDisplay, itemsToDisplay, props.currentUser.ID, room, setBookingsToDisplay, setItemsToDisplay]);

    const bookingDialogMeetingRoom = useMemo(() => {

        const handleCloseBookingDialog = () => {
            setShowMultiBookingMeetingRoomDialog(false);
            setSelectedSeat("");
            setClickedOnBookedSeat(false);
            setCurrentMeetingRoomBookings(null);
        }

        const bookingDialogProps: BookingDialogProps = {
            bookingType: "meetingRoom",
            handleClose: () => handleCloseBookingDialog(),
            dateToSelectInitially: date,
            bookerName: currentUser.name,
            bookerGivenName: currentUser.givenName,
            bookerFamilyName: currentUser.familyName,
            room: room,
            building: building,
            seatId: selectedSeat,
            selectedSeatIsBookedOnSelectedDay: clickedOnBookedSeat,
            selectedSeatIsBookedByMeOnSelectedDay: selectedSeatIsBookedByMe,
            bookingList: bookingList,
            roomCap: currentRoomCap,
            meetRoomType: currentMeetRoomType,
            seatConfNames: currentMeetingRoomBookings?.meetingRoom.meetingRoomSeatingConfNames ?? [],
            seatConfDaysInAdvance: currentMeetingRoomBookings?.meetingRoom.meetingRoomSeatingConfDays ?? 0,
            seatConfImageId: currentMeetingRoomBookings?.meetingRoom.meetingRoomSeatingConfImageId ?? ''
        }
        return (
            <Dialog PaperProps={isNoFullscreen ? undefined : paperProps} open={showMultiBookingMeetingRoomDialog}
                    onClose={handleCloseBookingDialog} scroll={"paper"}
                    style={isNoFullscreen ? {maxHeight: "650px"} : {}}>
                <BookingDialog {...bookingDialogProps}/>
            </Dialog>
        )
    }, [bookingList, clickedOnBookedSeat, currentRoomCap, currentUser.familyName, currentUser.givenName, currentUser.name, date, isNoFullscreen, paperProps, room, selectedSeat, selectedSeatIsBookedByMe, showMultiBookingMeetingRoomDialog])

    const renderRoomPlanComponent = useMemo(() => {
        return (
            <RoomPlanContext.Provider value={providerValues}>
                {renderRoomPlan}
                {renderItemClickedDialog}
                {renderMobileBookingButton}
                {bookingDialogSeat}
                {bookingDialogMeetingRoom}
                <RoomPlanPanningComponent selectedNeighborhood={selectedNeighborhood}
                                          triggerNeighborhoodUpdate={triggerNeighborhoodUpdate}
                                          roomFinderSelectedRoom={roomFinderSelectedRoom}
                                          triggerRoomFinderSelectedRoomUpdate={triggerRoomFinderSelectedRoomUpdate}
                                          isRoomDropdownFocussed={isRoomDropdownFocussed}
                                          isBuildingDropdownFocused={isBuildingDropdownFocused}
                                          zoomContext={zoomContext}
                                          roomPlanContainerRef={roomPlanContainerRef}></RoomPlanPanningComponent>
                {renderRoomPlanHoverDialog}
                {renderMeetingRoomBookingListHoverDialog}
            </RoomPlanContext.Provider>
        )
    }, [selectedNeighborhood, triggerNeighborhoodUpdate, providerValues, renderRoomPlan, bookingDialogSeat,
        renderItemClickedDialog, bookingDialogMeetingRoom, isRoomDropdownFocussed, isBuildingDropdownFocused,
        renderRoomPlanHoverDialog, renderMeetingRoomBookingListHoverDialog, roomFinderSelectedRoom,
        triggerRoomFinderSelectedRoomUpdate])

    return (<>{renderRoomPlanComponent}</>)
}
export default RoomPlanComponent
